<nav class="navbar navbar-expand-lg navbar-custom">
  <div class="container">
    <a class="navbar-brand" routerLink="/home">KAZIFLEX</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
      <fa-icon [icon]="farBars"></fa-icon>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
        <!-- Desktop Navbar Links -->
        <li class="nav-item">
          <a class="nav-link" aria-current="page" *ngIf="!authService.isAuthenticated()" routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" *ngIf="!authService.isAuthenticated()" routerLink="/browse-jobs" routerLinkActive="active">Browse Jobs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/about" routerLinkActive="active">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/faqs" routerLinkActive="active">FAQs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isFreelancer" routerLink="/find-work" routerLinkActive="active">Find Work</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isFreelancer" routerLink="/jobs" routerLinkActive="active">My Jobs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isAdmin" routerLink="/admin/users" routerLinkActive="active">Users</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-3" routerLink="/admin/faqs" *ngIf="authService.isAuthenticated() && isAdmin" routerLinkActive="active">FAQs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isAdmin" routerLink="/admin/jobs" routerLinkActive="active">Jobs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isClient" routerLink="/my-jobs" routerLinkActive="active">My Jobs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isClient" routerLink="/freelancers" routerLinkActive="active">Freelancers</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isClient" routerLink="/payments" routerLinkActive="active">Payments</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isFreelancer" routerLink="/earnings" routerLinkActive="active">Earnings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && (isClient || isFreelancer)" routerLink="/messages" routerLinkActive="active">Messages <span *ngIf="authService.unreadCount > 0" class="unread-count">{{authService.unreadCount}}</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="authService.isAuthenticated() && isAdmin" routerLink="/admin/transactions" routerLinkActive="active">Transactions</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/login" routerLinkActive="active"><fa-icon [icon]="userIcon"></fa-icon> LOG IN</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/register" routerLinkActive="active"><fa-icon [icon]="userPlusIcon"></fa-icon> SIGN UP</a>
        </li>
        <li class="nav-item dropdown" *ngIf="authService.isAuthenticated()">
          <a class="nav-link dropdown-toggle px-3" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <fa-icon [icon]="userCircleIcon" class="user-circle"></fa-icon>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="/account"><span class="user-icon"><fa-icon [icon]="userIcon"></fa-icon></span> Account</a>
            <a class="dropdown-item" *ngIf="isFreelancer" routerLink="/profile"><span class="user-icon"><fa-icon [icon]="userIcon"></fa-icon></span> Profile</a>
            <a class="dropdown-item" routerLink="/home" (click)="authService.logout()"><span class="user-icon"><fa-icon [icon]="signOutIcon"></fa-icon></span> Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Offcanvas Sidebar for Mobile -->
<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasNavbarLabel"><a class="navbar-brand" routerLink="/home" (click)="hideSidebar()">KAZIFLEX</a></h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" aria-current="page" *ngIf="!authService.isAuthenticated()" routerLink="/home" routerLinkActive="active" (click)="hideSidebar()">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" aria-current="page" *ngIf="!authService.isAuthenticated()" routerLink="/browse-jobs" routerLinkActive="active" (click)="hideSidebar()">Browse Jobs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/about" routerLinkActive="active" (click)="hideSidebar()">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/faqs" routerLinkActive="active" (click)="hideSidebar()">FAQs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isFreelancer" routerLink="/find-work" routerLinkActive="active" (click)="hideSidebar()">Find Work</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isFreelancer" routerLink="/jobs" routerLinkActive="active" (click)="hideSidebar()">My Jobs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isAdmin" routerLink="/admin/users" routerLinkActive="active" (click)="hideSidebar()">Users</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mx-3" routerLink="/admin/faqs" *ngIf="authService.isAuthenticated() && isAdmin" routerLinkActive="active" (click)="hideSidebar()">FAQs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isAdmin" routerLink="/admin/jobs" routerLinkActive="active" (click)="hideSidebar()">Jobs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isClient" routerLink="/my-jobs" routerLinkActive="active" (click)="hideSidebar()">My Jobs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isClient" routerLink="/freelancers" routerLinkActive="active" (click)="hideSidebar()">Freelancers</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isClient" routerLink="/payments" routerLinkActive="active" (click)="hideSidebar()">Payments</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isFreelancer" routerLink="/earnings" routerLinkActive="active" (click)="hideSidebar()">Earnings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && (isClient || isFreelancer)" routerLink="/messages" routerLinkActive="active" (click)="hideSidebar()">Messages <span *ngIf="authService.unreadCount > 0" class="unread-count">{{authService.unreadCount}}</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isAuthenticated() && isAdmin" routerLink="/admin/transactions" routerLinkActive="active" (click)="hideSidebar()">Transactions</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/login" routerLinkActive="active" (click)="hideSidebar()"><fa-icon [icon]="userIcon"></fa-icon> LOG IN</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" *ngIf="!authService.isAuthenticated()" routerLink="/register" routerLinkActive="active" (click)="hideSidebar()"><fa-icon [icon]="userPlusIcon"></fa-icon> SIGN UP</a>
      </li>
      <li class="nav-item" *ngIf="authService.isAuthenticated()">
        <a class="nav-link dropdown-toggle px-3" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <fa-icon [icon]="userCircleIcon"></fa-icon>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/account" (click)="hideSidebar()"><span class="user-icon"><fa-icon [icon]="userIcon"></fa-icon></span> Account</a>
          <a class="dropdown-item" *ngIf="isFreelancer" routerLink="/profile" (click)="hideSidebar()"><span class="user-icon"><fa-icon [icon]="userIcon"></fa-icon></span> Profile</a>
          <a class="dropdown-item" routerLink="/home" (click)="authService.logout()"><span class="user-icon"><fa-icon [icon]="signOutIcon"></fa-icon></span> Logout</a>
        </div>
      </li>
    </ul>
  </div>
</div>
